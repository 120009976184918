import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import './dark.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-top">
                <div className="footer-info footer-top-item">
                    <p className='footer-info-text'>Developed and maintained by:</p>
                    <p className='footer-info-text'>Yunus Sayed</p>
                </div>
                <div className="footer-contact footer-top-item">
                    <div className="footer-contact">
                        <div>
                    <a href="https://discord.com/invite/7knxcuA9E5" target="_blank" rel="noreferrer"><img className="footer-icons" src="/icons/discord.svg" alt="discord icon"/></a>
                    <a href="https://www.instagram.com/animeunsw/" target="_blank" rel="noreferrer"><img className="footer-icons" src="/icons/instagram.svg" alt="instagram icon"/></a>
                    <a href="https://www.facebook.com/unswanime" target="_blank" rel="noreferrer"><img className="footer-icons" src="/icons/facebook.svg" alt="facebook icon"/></a>
                        </div>
                        <Link to="/contact"><button className="contact-button">CONTACT</button></Link>
                    </div>
                </div>
            </div>
            <div className='footer-text-bottom'>© 2024 AnimeUNSW</div>
            <div className='bottom-container'>
                <span className='bottom-container-item'>
                    <img className="bottom-logo" src="/icons/AUNSW_white.png" alt="aunsw-logo"/>
                    <span className="bottom-text">ANIME UNSW</span>
                </span>
                <span className='bottom-container-item'>
                    <img className="bottom-logo" src="/icons/arc.png" alt="arc-clubs-logo"/>
                    <span className="bottom-text">SUPPORTED BY ARC UNSW</span>
                </span>
            </div>
        </footer>
    );
}

export default Footer;

