import React from 'react';
import './Homepage.css'; 

const Homepage = () => {
  return (
    <div className="home-container">
      <section className="home-hero">
        <h1 className='hero-title'>The society for weebs, by weebs</h1>
        <a href="https://discord.com/invite/7knxcuA9E5" target="__blank"><button className="join-button">JOIN US NOW!</button></a>
      </section>
      
    </div>
  );
};

export default Homepage;

      // <section className="home-about">
      //   <h2>WHO WE ARE</h2>
      //   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
      // </section>
      //
      // <section className="home-members">
      //   <h2>OUR MEMBERS</h2>
      //   {/* Add content for members section */}
      // </section>
      //
      // <section className="home-what-we-do">
      //   <h2>WHAT WE DO</h2>
      //   <div className="events-preview">
      //     {/* Add events preview cards */}
      //   </div>
      // </section>
      // 
      // <section className="home-sponsors">
      //   <h2>THANKS TO OUR SPONSORS</h2>
      //   {/* Add sponsors logos */}
      // </section>
