// Events.js

import React from "react";
import "./Events.css"; 
const eventsData = [
  {
    name: "Fun Social Events",
    description:
      "Exotic anime-themed events immerse attendees in exotic experiences filled with entertaining activities, laughter, and opportunities to make new friends.",
    image: "https://animeunsw.net/events/image1.JPG",
  },
  {
    name: "Out of Uni Events",
    description:
      "Out-of-uni night events provide students a fun escape with engaging activities and socializing opportunities beyond campus life.",
    image: "https://animeunsw.net/events/image12.jpg",
  },
  {
    name: "Kick Off Party!",
    description: "A place to make new lifelong friends with anime and manga fans, filled with events and giveaways for the whole night!",
    image: "https://animeunsw.net/events/image3.jpg",
  },
  {
    name: "City Crawls",
    description:
      "Going to various places including: Kaika, Timezone, Capital Sq and Koko Amusement for packed yet entertaining activities.",
    image: "https://animeunsw.net/events/image15.jpg",
  },
  {
    name: "Cosplay Events",
    description:
      "Vibrant gatherings where fans celebrate by dressing as favorite characters, sharing creativity, and fostering community.",
    image: "https://animeunsw.net/events/image5.jpg",
  },
  {
    name: "Art Events",
    description:
      "Chill art events are relaxed gatherings where participants enjoy and create art in a laid-back, supportive community atmosphere.",
    image: "https://animeunsw.net/events/image6.jpg",
  },
  {
    name: "Online Events",
    description:
      "Online events where members can engage, learn, and connect from the comfort of their own spaces.",
    image: "https://animeunsw.net/events/image16.jpg",
  },
  {
    name: "Collab Events",
    description:
      "Collaboration events with other societies within UNSW allow for dynamic meetups for students to network, share ideas, and enjoy diverse club activities.",
    image: "https://animeunsw.net/events/image11.jpg",
  },
  {
    name: "Chill Weekly Events",
    description:
      "Weekly chill event offer a consistent, cozy space for community bonding over comforting routines and interests.",
    image: "https://animeunsw.net/events/image9.JPG",
  },
];

const EventCard = ({ event }) => {
  return (
    <div
      className="event-image"
      style={{ backgroundImage: `url(${event.image})` }}
    >
      <div className="event-card">
        <div className="event-name">{event.name}</div>
        <div className="event-description">{event.description}</div>
      </div>
    </div>
  );
};

const Events = () => {
  return (
    <div className="main-content">
      <div className="content-container" style={{ maxWidth: "1500px" }}>
        <div className="events-header">EVENTS</div>
        <div className="events-list">
          {eventsData.map((event, index) => (
            <EventCard key={index} event={event} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Events;
