import React, { useState } from "react";
import "./FAQ.css";
import "./main.css";

const faqData = [
  {
    question: "How do I come to events?",
    answer:
      "You can find information about our events on our social media such as our instagram and discord",
  },
  {
    question: "Do I need to be a member to come to events?",
    answer:
      "You will need to be a member to join our events in-person event, everyone is welcome to join events in this server though!",
  },
  {
    question:
      "Do I need to be a member/UNSW student to be verified in the Discord server?",
    answer:
      "Anyone can be verified to our server as long as you fill in the verification form correctly. You do not have to be a member to participate in server activities!",
  },
  {
    question: "What are the membership benefits?",
    answer:
      "Being a member will allow you to attend all our in-person events while also getting membership discounts with some of our sponsors.",
  },
  {
    question: "How do I become a member?",
    answer:
      "Easiest way is to come to an in-person event and we can walk you through the process on the spot as well as give you your AUNSW membership card.",
  },
  {
    question: "I have a grievance with another member! What should I do?",
    answer:
      "If there are any grave concerns or complaints, please contact us at unswanime@gmail.com",
  },
  // Add more questions and answers here
];

const FAQItem = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`faq-item ${isOpen ? "open" : ""}`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="faq-question">
        Q. {faq.question}
        <span className="faq-toggle">{isOpen ? "▼" : "▲"}</span>
      </div>
      {isOpen && <div className="faq-answer">A. {faq.answer}</div>}
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="main-content">
      <div className="content-container">
        <div className="faq-page">
          <h1 className="faq-header">FAQ</h1>
          {faqData.map((faq, index) => (
            <FAQItem key={index} faq={faq} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
