import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navigation.css";
import "./dark.css";

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="navbar">
      <div className="header-top">
        <span className="header-spacer"></span>
        <span className="header-title">
          <span>
            <img
              className="header-logo"
              src="/icons/AUNSW_white.png"
              alt="Anime UNSW Logo"
            />
          </span>
          <span className="header-text">ANIME UNSW</span>
        </span>
        <span className="header-icon-container">
          <a
            href="https://discord.com/invite/7knxcuA9E5"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="header-icons"
              src="/icons/discord.svg"
              alt="discord icon"
            />
          </a>
          <a
            href="https://www.instagram.com/animeunsw/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="header-icons"
              src="/icons/instagram.svg"
              alt="instagram icon"
            />
          </a>
          <a
            href="https://www.facebook.com/unswanime"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="header-icons"
              src="/icons/facebook.svg"
              alt="facebook icon"
            />
          </a>
        </span>
      </div>
      <div class="hamburger-menu" onClick={toggleMenu}>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
      <ul className={`navbar-menu ${isOpen ? "active" : ""}`}>
        <li className="navbar-item">
          <Link to="/" className="navbar-link">
            HOME
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/events" className="navbar-link">
            EVENTS
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/sponsors" className="navbar-link">
            SPONSORS
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/faq" className="navbar-link">
            FAQ
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/contact" className="navbar-link">
            CONTACT
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;

// <a href="" target=""><img className="header-icons" src="/icons/tiktok.svg" alt="tik tok icon"/></a>
