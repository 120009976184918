import React from "react";
import "./Sponsors.css";

const sponsors = [
  {
    name: "Anime at Abbotsford",
    logo: "https://animeunsw.net/sponsors/black.png",
    web: "https://www.anime-oz.com/",
    description: "Use code: ANIMESYDNEY for 5% off first box",
  },
  {
    name: "Anime Kaika",
    logo: "https://animeunsw.net/sponsors/AnimeKaika.png",
    web: "https://www.kaika.com.au/",
    description: "Show card for 5% off",
  },
  {
    name: "Books Kinokuniya",
    logo: "https://animeunsw.net/sponsors/logo_1.png",
    web: "https://kinokuniya.com.au/",
    description: "Provides goods for AUNSW Events/Giveaways",
  },
  {
    name: "Crunchyroll",
    logo: "https://animeunsw.net/sponsors/cr.png",
    web: "https://www.crunchyroll.com/",
    description: "Provides goods for AUNSW Events/Giveaways",
  },
  {
    name: "Dendy Cinemas Newtown",
    logo: "https://animeunsw.net/sponsors/Dendy.jpg",
    web: "https://newtown.dendy.com.au/home?preferred",
    description: "Provides goods for AUNSW Events/Giveaways",
  },
  {
    name: "Double Kill Games",
    logo: "https://animeunsw.net/sponsors/double_kill.jpg",
    web: "https://www.facebook.com/doublekillgamesau/",
    description: "Provides goods for AUNSW Events/Giveaways",
  },
  {
    name: "Genshin Whales",
    logo: "https://animeunsw.net/sponsors/genshin_whale_300x300.png",
    web: "https://www.genshinwhales.com.au/",
    description: "Use code: ANIMESYDNEY5 for 5% off",
  },
  {
    name: "Harajuku",
    logo: "https://animeunsw.net/sponsors/harajuku-gyoza_master_pos_rgb.png",
    web: "https://www.harajukugyoza.com/",
    description: "20% off Mon - Friday",
  },
  {
    name: "Hondarake",
    logo: "https://animeunsw.net/sponsors/hondarake.png",
    web: "https://fullofbooks.com.au/",
    description: "5% discount on purchasing books, CDs/DVDs, toys etc *except for discounted items, cosmetics and consignment items",
  },
  {
    name: "Iced Tea Aesthetic",
    logo: "https://animeunsw.net/sponsors/ita.png",
    web: "https://www.icedtea-aesthetics.com/",
    description: "Use code: ANIMESYD - 10% OFF, ANIMESYD20 - 20% OFF (3+ ITEMS), ANIMESYDSHIP FREE SHIPPING",
  },
  {
    name: "Isekai Gacha",
    logo: "https://animeunsw.net/sponsors/isekai_gacha.png",
    web: "https://isekaianime.com.au/",
    description: "Use code: isekaixanisyd24 - 10% off",
  },
  {
    name: "Ito En",
    logo: "https://animeunsw.net/sponsors/Ito En.png",
    web: "https://itoen.com.au/",
    description: "Provides goods for AUNSW Events/Giveaways",
  },
  {
    name: "Japan Toys",
    logo: "https://animeunsw.net/sponsors/Japantoys.png",
    web: "https://www.japantoys.com.au/",
    description: "Use code ANISYD24 for 10% off",
  },
  {
    name: "Kahweiei",
    logo: "https://animeunsw.net/sponsors/Pink Circle Logo.png",
    web: "https://kahweiei.com/",
    description: "Use code: ANISYD10 for 10% off",
  },
  {
    name: "Kingpin",
    logo: "https://animeunsw.net/sponsors/Kingpin Logos-01.jpg",
    web: "http://www.kingpinplay.com/",
    description: "Provides goods for AUNSW Events/Giveaways",
  },
  {
    name: "Luck of Kings",
    logo: "https://animeunsw.net/sponsors/luck_of_kings.PNG",
    web: "https://luckofkings.com/",
    description: "",
  },
  {
    name: "Lumins Workshop",
    logo: "https://animeunsw.net/sponsors/New logo-03.png",
    web: "https://www.luminsworkshop.com/",
    description: "Use code: 'ANISYD24' for 10% off ",
  },
  {
    name: "Manga Mate",
    logo: "https://animeunsw.net/sponsors/manga-mate-logo-white_x50@2x.png",
    web: "https://mangamate.shop/",
    description: "Use code: animesydney for 10% online discount or code: animesydney20 for 20% first purchase",
  },
  {
    name: "Maruyu",
    logo: "https://animeunsw.net/sponsors/MaruyuPNG.png",
    web: "https://www.maruyu.com.au/",
    description: "Provides goods for AUNSW Events/Giveaways",
  },
  {
    name: "Musou Yakiniku",
    logo: "https://animeunsw.net/sponsors/Musou.PNG",
    web: "https://www.musou.com.au/",
    description: "Show card for 5% off for members or 10% off for lunch box menu",
  },
  {
    name: "Otaku Cave AU",
    logo: "https://animeunsw.net/sponsors/Otaku Cave Logo 1 (1)_preview_rev_1.png",
    web: "https://www.otakucave.com.au/",
    description: "Show member card for 20% off storefront (Ashfield)",
  },
  {
    name: "Patches Paradise",
    logo: "https://animeunsw.net/sponsors/LOGO PNG-04.png",
    web: "https://patchesparadise.com/",
    description: "Use code: ANIMESYDNEY for $30 off only in March and October",
  },
  {
    name: "Roll with Advantage",
    logo: "https://animeunsw.net/sponsors/roll.webp",
    web: "https://rollwithadvantage.com.au/",
    description: "",
  },
  {
    name: "Saigon Brew",
    logo: "https://animeunsw.net/sponsors/saigon_brew.PNG",
    web: "https://saigonbrew.com.au/",
    description: "",
  },
  {
    name: "Sakuraco",
    logo: "https://animeunsw.net/sponsors/SC 2.0 Logo_Stacked_Compact.png",
    web: "https://sakura.co/",
    description: "Use code: ANIMESYDNEY for 5% off first box",
  },
  {
    name: "Seconds Please!",
    logo: "https://animeunsw.net/sponsors/Secondsplease.png",
    web: "https://secondsplease.jp/",
    description: "Use code: ANIMESYD10 for 10% off",
  },
  {
    name: "Sugoico AU",
    logo: "https://animeunsw.net/sponsors/sugoico.jpg",
    web: "https://www.facebook.com/sugoicoau/",
    description: "Provides goods for AUNSW Events/Giveaways",
  },
  {
    name: "Tenshukaku",
    logo: "https://animeunsw.net/sponsors/Long COLOR.png",
    web: "https://tenshukaku.com.au/",
    description: "Use code: ANIMESYD10 for 10% discount",
  },
  {
    name: "Timezone",
    logo: "https://animeunsw.net/sponsors/Timezone Logo-01.png",
    web: "https://www.timezonegames.com/en-au",
    description: "Provides goods for AUNSW Events/Giveaways",
  },
  {
    name: "Via Artists",
    logo: "https://animeunsw.net/sponsors/via-artists-logo.png",
    web: "https://viaartists.com/",
    description: "Show member card or use code: ANISYD24 for 10% off",
  },
  {
    name: "Wuta Bistro",
    logo: "https://animeunsw.net/sponsors/wutabistroblack.png",
    web: "https://www.keeyuen.com.au/project/wuta-bistro-%E7%84%A1ta%C2%B7%E5%B7%9D%E8%8F%9C/",
    description: "Show card for 10% off",
  },
  {
    name: "Yasaka Ramen",
    logo: "https://animeunsw.net/sponsors/Yasaka.png",
    web: "https://www.facebook.com/yasakaramen/",
    description: "Show card for 10% off",
  },
  {
    name: "Yume Twins",
    logo: "https://animeunsw.net/sponsors/YumeTwins-NewLogo.png",
    web: "https://yumetwins.com/",
    description: "Use code: ANIMESYDNEY for 5% off first box",
  },
];

const SponsorItem = ({ sponsor }) => {
  return (
    <a href={sponsor.web} target="__blank">
        <div className="sponsor-card">
                <div className="sponsor-details">
                    <h2 className="sponsor-name">{sponsor.name}</h2>
                    <p className="sponsor-description">{sponsor.description}</p>
                </div>
                <img className="sponsor-logo" src={sponsor.logo} alt={sponsor.name}/>
        </div>
    </a>
  );
};

const Sponsors = () => {
  return (
    <div className="main-content">
      <div className="content-container" style={{ maxWidth: "100%" }}>
        <h1 className="sponsors-header">SPONSORS</h1>
        <div className="sponsors-list">
          {sponsors.map((sponsor, index) => (
            <SponsorItem key={index} sponsor={sponsor} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
