import React from 'react';
import './Contact.css';
import './dark.css';
import './main.css';

const Contact = () => {
    return (
        <div className='main-content'>
            <div className="contact-page">
                <div className="content-container">
                    <h1 className="contact-header">
                        CONTACT
                    </h1>
                    <h2 className="contact-tagline">
                        Want to get into contact?
                    </h2>
                    <p className="contact-text">
                        We'd love to hear from you! Whether you have a question, feedback, or just want to say hello, we're here to listen.

<h4>General Enquiries:</h4>
For all general questions and information, please don't hesitate to reach out. Simply click the big "Send an Email" button on our site, and we'll get back to you as soon as we can.

<h4>Sponsorship Enquiries:</h4>
Interested in collaborating with AUNSW as a sponsor? We welcome partnerships that can enhance the experiences of our anime community. Please direct all sponsorship-related communications to our external relations director Dylan at <a href="mailto:sponsors@animeunsw.net" style={{color: "var(--pink-text)"}}>sponsors@animeunsw.net</a>, and let's explore how we can work together.

<h4>Stay Connected:</h4>
Don't miss out on any updates or announcements. Follow us on our social media channels for our latest event details, and more from AUNSW.

Thank you for your interest in AUNSW. We're looking forward to connecting with you!
                    </p>
                    <div className='flex'>
                    <a href="mailto:unswanime@gmail.com" className='margin-auto'><button className="email-button">
                        SEND AN EMAIL!
                    </button></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;

